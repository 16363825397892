@media screen and (min-width: 1024px) {
    html {
        scroll-snap-type: y mandatory;
    }

    body {

        &>main,
        &>footer,
        &>div {
            scroll-snap-align: start;
            scroll-snap-stop: always;
        }
    }

    header {
        position: relative;
    }

    .menu {
        display: none;

        &_next {
            display: none;
        }
    }

    .column {
        display: flex;
        flex-direction: row;
        min-height: 100vh;
        justify-content: center;

        &.column-reverse {
            flex-direction: row-reverse;
        }

        &>* {
            flex: 1;
            height: auto;
        }

        section {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .section_wrapper {
        padding: 2.5rem;
    }

    main,
    footer {
        &>div {
            margin: 0 auto;
            width: 600px;
            background-color: white;
            border-radius: 15px;
        }

        min-height: 100vh;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    main {
        background-image: url(./../img/haare.jpg);
        background-size: cover;

        .section_wrapper {
            margin: 1rem auto;
        }
    }

    .contact-wrapper {
        display: flex;
        flex-direction: row;

        &>* {
            flex: 1;
        }

        .contract-bg {
            background-image: url('./../img/alex3.jpg');
            background-size: cover;
        }
    }

    .hidden.d-block {
        display: block !important;
    }
}