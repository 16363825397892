
.icon {
    background-size: cover;
    background-position: center center;
    display:inline-block;
    width:.8em;
    height:.8em;
    color: white;
    filter: saturate(0%) brightness(100%);

    &.rotate {
        transform:rotate(180deg);
    }

    &-lg {
        width:1.2em;
        height:1.2em;
    }

    &-arrow-down {
        background-image:url('./../img/icon-arrow.svg')
        
        
    }
}

a > .icon {

    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}