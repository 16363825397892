*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  font-family: sans-serif;
  line-height: 1.15;
}

@-ms-viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #212529;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre, code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a, area, button, [role="button"], input:not([type="range"]), label, select, summary, textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  color: #868e96;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  width: 100%;
  max-width: 100%;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

:is([type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

:is([type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration) {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.icon {
  color: #fff;
  filter: saturate(0%) brightness();
  background-position: center;
  background-size: cover;
  width: .8em;
  height: .8em;
  display: inline-block;
}

.icon.rotate {
  transform: rotate(180deg);
}

.icon-lg {
  width: 1.2em;
  height: 1.2em;
}

.icon-arrow-down {
  background-image: url("icon-arrow.e6b38a7f.svg");
}

a > .icon, a > .icon:hover {
  text-decoration: none;
}

html {
  margin: 0;
  padding: 0;
}

body {
  font-family: Barlow, sans-serif;
}

body > main, body > footer, body > div {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

h1 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.3rem;
}

h2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 2.1rem;
  font-weight: 600;
  line-height: 2.2rem;
}

p, li {
  font-size: 1.05rem;
  font-weight: 400;
}

img {
  width: auto;
  height: 100%;
}

.hidden {
  display: none;
}

.icon-schere {
  width: 150px;
  padding: 2rem;
  display: inline-block;
}

.text-center {
  text-align: center;
}

.arrow-down {
  border-bottom: 2px solid #000;
  border-left: 2px solid #000;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: rotate(-45deg);
}

header {
  background-image: url("alex.ab8f2344.jpg");
  background-size: cover;
  height: 100vh;
  position: relative;
}

header .menu {
  z-index: 10;
  background-color: #fff;
  width: 100%;
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
}

header .menu_content {
  text-align: left;
  height: 100%;
  padding: 1rem;
}

header .menu_content img {
  float: left;
}

header .menu_slogan {
  z-index: 20;
  letter-spacing: -.1rem;
  text-align: center;
  text-transform: uppercase;
  background-color: #fff6;
  width: 100%;
  padding: 1.5rem 0;
  font-size: 1.6rem;
  line-height: 2rem;
  position: absolute;
  bottom: 5rem;
}

header .menu_next {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 2rem;
}

header .menu_next i {
  color: #fff;
  border-color: #fff;
}

section {
  min-height: 100vh;
}

.section_wrapper, footer {
  padding: 2rem;
}

.section-subheader-bg {
  background-image: url("shampoo.eaa0366f.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 15px;
  width: 100%;
  height: 300px;
}

.section-subheader-bg--marriage {
  background-image: url("marriage3.b6b67ad0.jpg");
}

.section-subheader h2 {
  margin-top: -105px;
  padding: 0 1rem;
  font-size: 3.3rem;
  font-weight: 600;
  line-height: 3.2rem;
}

.hero-image1 {
  background-image: url("hero1.f599aceb.jpg");
  background-position: center;
  background-size: cover;
}

.hero-image2 {
  background-image: url("marriage.b01e4ade.jpg");
  background-position: center;
  background-size: cover;
}

.klappbox {
  margin-bottom: .5rem;
}

.klappbox-header {
  color: #fff;
  background-color: #5c5c5c;
  justify-content: space-between;
  padding: .5rem 1rem;
  display: flex;
}

.klappbox-content {
  margin-bottom: 1rem;
  padding: .5rem;
}

footer .icon {
  color: inherit;
}

@media screen and (width >= 1024px) {
  html {
    scroll-snap-type: y mandatory;
  }

  body > main, body > footer, body > div {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }

  header {
    position: relative;
  }

  .menu, .menu_next {
    display: none;
  }

  .column {
    flex-direction: row;
    justify-content: center;
    min-height: 100vh;
    display: flex;
  }

  .column.column-reverse {
    flex-direction: row-reverse;
  }

  .column > * {
    flex: 1;
    height: auto;
  }

  .column section {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  .section_wrapper {
    padding: 2.5rem;
  }

  main, footer {
    flex-direction: row;
    align-items: center;
    min-height: 100vh;
    display: flex;
  }

  main > div, footer > div {
    background-color: #fff;
    border-radius: 15px;
    width: 600px;
    margin: 0 auto;
  }

  main {
    background-image: url("haare.382717e6.jpg");
    background-size: cover;
  }

  main .section_wrapper {
    margin: 1rem auto;
  }

  .contact-wrapper {
    flex-direction: row;
    display: flex;
  }

  .contact-wrapper > * {
    flex: 1;
  }

  .contact-wrapper .contract-bg {
    background-image: url("alex3.7a33427f.jpg");
    background-size: cover;
  }

  .hidden.d-block {
    display: block !important;
  }
}

/*# sourceMappingURL=index.b91beafe.css.map */
