@import "reboot.less";
@import "icon.less";

html {
    margin: 0;
    padding: 0;
}

body {
    font-family: "Barlow", sans-serif;

    & > main,
    & > footer, 
    & > div {
        scroll-snap-align: start;
        scroll-snap-stop: always;
    }
}

h1 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.3rem;
}

h2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 2.1rem;
    font-weight: 600;
    line-height: 2.2rem;
}

p,
li {
    font-weight: 400;
    font-size: 1.05rem;
}

img {
    width: auto;
    height: 100%;
}

.hidden {
    display: none;
}

.icon-schere {
    display: inline-block;
    width: 150px;
    padding: 2rem;
}

.text-center {
    text-align: center;
}


.arrow-down {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-left: solid 2px black;
    border-bottom: solid 2px black;
    transform: rotate(-45deg);
}

header {
    height: 100vh;

    background-image: url("./../img/alex.jpg");
    background-size: cover;
    position: relative;

    .menu {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 90px;
        background-color: white;
        z-index: 10;

        &_content {
            height: 100%;
            padding: 1rem;
            text-align: left;

            img {
                float: left;
            }
        }

        &_slogan {
            position: absolute;
            width: 100%;
            bottom: 5rem;
            z-index: 20;
            font-size: 1.6rem;
            letter-spacing: -.1rem;
            line-height: 2rem;
            padding: 1.5rem 0rem;
            background-color: rgba(255, 255, 255, 0.4);
            text-align: center;
            text-transform: uppercase;
        }

        &_next {
            position: absolute;
            width: 100%;
            bottom: 2rem;
            text-align: center;

            i {
                color: white;
                border-color: white;
            }
        }


    }
}

section {
    min-height: 100vh;
}

.section_wrapper,
footer {
    padding: 2rem;
}

.section-subheader {
    &-bg {
        width: 100%;
        background-image: url('./../img/shampoo.jpg');
        background-size: cover;
        height: 300px;
        border-radius: 15px;
        background-position: center center;

        &--marriage {
            background-image: url('./../img/marriage3.jpg');
        }

    }


    h2 {
        margin-top: -105px;
        font-size: 3.3rem;
        font-weight: 600;
        line-height: 3.2rem;
        padding: 0 1rem;
    }
}

.hero-image1 {
    background-image: url('./../img/hero1.jpg');
    background-size: cover;
    background-position: center center;
}

.hero-image2 {
    background-image: url('./../img/marriage.jpg');
    background-size: cover;
    background-position: center center;
}


.klappbox {
    margin-bottom: .5rem;

    &-header {
        background-color: #5c5c5c;
        padding: .5rem 1rem;
        color: white;
        display: flex;
        justify-content: space-between;

    }

    &-content {
        padding: .5rem;
        margin-bottom: 1rem;
    }
}

footer {
    .icon {
        color: inherit;
    }
}






/* import the media queries here */
@import "desktop.less";